.priority_box {
  background-color: tomato;
  border: 2px solid greenyellow;
}

.dropdown {
  position: relative;
  user-select: none;
}

.dropdown__button {
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 25px;
  min-width: 300px;
}

.dropdown__button p {
  color: #333;
  font-family: 'Inter', 'raleway', sans-serif !important;
  font-size: 1.2em !important;
  font-weight: 400;
  margin: 0;
}

.dropdown__icon {
  align-items: center;
  border: 1px solid #333;
  display: flex;
  height: 52px;
  justify-content: center;
  margin-right: -26px;
  min-width: 52px;
}

.dropdown__content {
  background-color: #fff;
  border: 1px solid #333;
  color: #333;
  padding: 10px 25px;
  position: absolute;
  top: 57px;
  width: 100%;
  z-index: 1200;
}

.dropdown__button:hover, .dropdown__content:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dropdown__item {
  background-color: transparent;
  border: none;
  color: #677897;
  cursor: pointer;
  display: flex;
  font-family: 'Inter', 'raleway', sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  padding: 10px 0;
  text-align: start;
  transition: all 0.2s;
  width: 100%;
}

.vacantest__checkboxes__row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkboxes__holder {
  min-width: 300px;
}

.vacantest__checkboxes__item__name {
  width: 55%;
}

.vacantest__checkboxes {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-width: 250px;
}

.vacantest__individual__checkbox__title {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30%;
}

.vacantest__individual__checkbox__title h3 {
  color: #333;
  font-size: 1.3em;
}

.dropdown__item:hover {
  background-color: #eee;
}

@media all and (max-width: 1439px) {
  .dropdown__button {
    padding: 25px 10px;
    min-width: 225px;
    width: 225px;
  }

  .checkboxes__holder {
    min-width: 225px;
  }

  .vacantest__checkboxes {
    min-width: 180px;
  }

  .dropdown__icon {
    margin-right: -11px;
  }
}

@media all and (max-width: 1023px) {
  .dropdown__button {
    padding: 25px 5px;
    min-width: 190px;
    width: 190px;
  }

  .checkboxes__holder {
    min-width: 190px;
  }

  .vacantest__checkboxes {
    min-width: 170px;
  }

  .dropdown__icon {
    margin-right: -6px;
  }
}

@media all and (max-width: 767px) {
  .dropdown__button {
    padding: 25px 5px;
    min-width: 180px;
    width: 180px;
  }

  .checkboxes__holder {
    min-width: 180px;
  }

  .vacantest__checkboxes {
    min-width: 150px;
  }
}