.vacantest__quantity__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7.5%;
  width: 150px;
}

.vacantest__dropdown__container {
  margin-top: 5%;
}

.vacantest__quantity__btn,
.vacantest__add__dropdown__btn {
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.vacantest__quantity__btn {
  border: 1px solid #58a6e1;
  height: 45px;
  width: 45px;
}

.vacantest__add__dropdown__btn {
  border: 1px solid #333;
  height: 35px;
  width: 35px;
}

.vacantest__quantity__btn:hover:enabled {
  border: 2px solid #58a6e1;
}

.vacantest__add__dropdown__btn:enabled {
  border: 2px solid #333;
}

.vacantest__quantity__btn:disabled,
.vacantest__add__dropdown__btn:disabled {
  opacity: 0.5;
}

.quantity__span {
  color: #333;
  font-family: 'Inter', 'raleway', sans-serif;
  font-size: 1.3em;
  font-weight: 600;
}

.vacantest__add__level__button {
  background-color: transparent;
  border: none;
  align-items: center;
  display: flex;
}

.vacantest__add__level__button p {
  margin-left: 10px;
  color: #58a6e1;
  font-family: 'Inter', 'raleway', sans-serif;
  font-weight: 400;
}
