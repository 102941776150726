.home {
  /* display: flex; */
  background-color: #ffffff;

  &__video-banner {
      position: relative;
      /* margin: auto; */
      z-index: 1;
      /* max-height: 600px; */
  
      &__container {
        height: 600px;
        display: flex;
        justify-content: center;
        position: relative;
        overflow: hidden;
  
        .banner-image {
          max-height: 100%;
          width: 100%;
          top: 0;
          position: absolute;
          z-index: -1;
        }
        
        .banner-video {
          width: 100%;
          height: 600px;
          top: 0;
          position: absolute;
          z-index: -1;
          object-fit: cover;
        }
        
        .banner-content {
          display: flex;
          flex: 1;
          position: relative;
          top: 120px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          max-width: 686px;
          padding: 0px 24px 0px 24px;
          gap: 48px;
          height: fit-content;
          
          h1, h3 {
            width: 100%;
            font-style: normal;
            text-align: center;
            color: #FFFFFF;
            margin: 0;
            white-space: pre-line
          }
          
          h1 {
            font-size: 40px;
            font-weight: 500;
            line-height: 48px;
            text-align: center;
    
            span {
              color: #5EACFA;
            }
          }
          
          h3 {
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
    
            span {
              font-size: inherit;
              font-weight: 600;
              line-height: 29.05px;
              text-align: inherit;
            }
          }
        }
        
        .banner-icon {
          position: absolute;
          /* Guard for old browsers */
          right: 70%;
          right: max(70%, calc(100% - 306px));
          bottom: -40px;
        }
        
        .banner-background {
          background: rgba(0, 0, 0, 0.7);
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          z-index: -1;
        }

        @media all and (max-width: 485px) {
          .banner-content {
            
            h1 {
              font-size: 32px;
              line-height: 40px;
            }
            
            h3 {
              font-size: 16px;
              line-height: 20px;

              span {
                line-height: 20px;
              }
            }
          }
        }
      }
  }

  &__section {
    display: flex;
    justify-content: center;
    padding-left: 32px;
    padding-right: 32px;
    background-color: #f7f9fc;

    @media all and (max-width: 485px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__benefits-section {
    display: flex;
    justify-content: center;
    background-color: transparent;
    height: fit-content;
    position: relative;
    margin-top: -54px;
    z-index: 2;

    @media all and (max-width: 824px) {
      margin-top: -75px;
    }

    @media all and (max-width: 485px) {
      margin-top: -100px;
    }

    & .experience-benefits-banner-container {
      display: flex;
      flex-direction: row;
      flex: 1;
      gap: 20px;
      align-items: center;
      background-color: #ffffff;
      padding: 24px 32px 24px 32px;
      border-top: 4px solid #0096ED;
      border-radius: 4px;
      box-shadow: 1px 2px 40px 0px #0000001F;
      max-width: 900px;

      .experience-benefits-banner {
        width: 100%;
      }

      @media all and (max-width: 824px) {
        padding: 24px 20px 24px 20px;
        .experience-benefits-banner {
          flex-direction: column;

          .experience-benefits-banner__content__container {
            flex-direction: column;
            align-items: center;
            
            & * {
              text-align: center!important;
            }

            .experience-benefits-banner__button {
              margin-left: unset;
            }
          }
        }
      }
    }
  }

  &__simple-section {
    padding-top: 80px;
    padding-bottom: 80px;
    gap: 60px;

    @media all and (max-width: 485px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 1128px;
      gap: 10%;
      
      &.reverse {
        flex-direction: row-reverse;
      }

      &:not(.vertical) > div {
        display: flex;
        flex: 1 0 45%;
      }

      &__readable {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__text {
          display: flex;
          flex-direction: column;
          gap: 16px;
          
          &__header {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            text-align: left;
            color: #0096ED;

            @media all and (max-width: 485px) {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.1px;
            }
          }

          &__title { 
            font-size: 32px;
            font-weight: 500;
            line-height: 40px;
            text-align: left;
            color: #1A1A1A;

            @media all and (max-width: 485px) {
              font-size: 28px;
              line-height: 36px;
            }
          }

          &__subtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #1A1A1A;
          }
        }

        &__action {
          display: flex;
        }
      }

      &__complement {
        max-width: 1128px;
        flex: 1 1 auto;
        display: flex;
      }
      
      &__illustration {
        align-items: center;
        justify-content: center;
        
        &__wrapper {
          display: block;
          width: 100%;
          
          &__image {
            width: 100%;
            height: auto;
          }
        }
      }

      &.vertical {
        flex-direction: column!important;
        max-width: 792px;
        gap: 48px;

        .home__simple-section__content__readable {
          align-items: center;
          
          &__text {
            align-items: center;
            & > * {
              text-align: center;
            }
          }
        }

        .home__simple-section__content__illustration {
          max-width: 520px;
        }
      }
    }

    &.colored {
      background-color: #001D34;

      & .home__simple-section__content__readable__text__title,
      & .home__simple-section__content__readable__text__subtitle {
        color: #ffffff;
      }
    }
  }

  .howitworks {
    .MuiStepper-root {
      .MuiStep-horizontal {
        padding-left: 16px;
        padding-right: 16px;
        width: 292px;
      }

      .MuiStepLabel-label {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .step-title {
          font-family: 'Inter';
          font-size: 22px;
          font-weight: 500;
          line-height: 28px;
          text-align: center;
          color: #1A1A1A;
        }
    
        .step-subtitle {
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.5px;
          text-align: center;
          color: #1A1A1A;
        }
      }

      &.MuiStepper-vertical {
        .MuiStepLabel-root {
          gap: 24px;
        }

        .MuiStepConnector-line {
          min-height: 40px;
        }

        .MuiStepLabel-label {
          gap: 8px;
  
          .step-title {
            text-align: left;
          }
      
          .step-subtitle {
            text-align: left;
          }
        }
      }
      
      .MuiSvgIcon-root {
        background-color: transparent;
        fill: #0096ED;
        transform: scale(1.5);
      }

      @media all and (max-width: 485px) {
        .MuiStepLabel-root {
          gap: 12px!important;
        }
        .MuiStepLabel-label {
          .step-title {
            font-size: 20px;
            line-height: 28px;
          }
          .step-subtitle {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
          }
        }
      }
    }
  }

  .testimonials {
    width: 80vw;
    max-width: 800px;

    &__testimonial {
      display: flex;
      flex-direction: row;
      margin: 4px;
      background-color: #ffffff;
      padding: 32px 32px 24px 32px;
      gap: 20px;
      border-radius: 20px;
      border: 0.85px solid #DDDDDD;
      box-shadow: 2px 2px 6px 0px #0000001F;

      &__icon {
        width: 60px;
        height: 60px;
      }
      
      &__content {
        &__text {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }

        &__author {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
          text-align: left;
          color: #666666;
        }
        
        &__action {
          padding-left: 0;
          padding-right: 0;
          
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
        }
      }

      @media all and (max-width: 485px) {
        padding: 24px;

        &__content {
          &__text {
            letter-spacing: 0px;
          }
        }
        
        &__icon {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

}

@media all and (max-width: 1239px) {
  .banner-content h1 span {
    display: block;
  }
}

@media all and (max-width: 905px) {
  .banner-content {
    max-width: 90%;
  }
}

@media all and (max-height: 599px) {
  .banner-content h1, .banner-content h3, .banner-content .button {
    margin-top: 24px;
  }
  .banner-icon {
    display: none;
  }
}