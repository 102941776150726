#benefitsData {
  .registration-info {
    display: flex;
    flex-direction: column;
    gap: 0!important;
    // width: 600px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 48px;

      &__section {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__title {
          display: flex;
          flex-direction: row;
          align-items: center;

          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;

          button {
            margin-left: auto;
          }
        }
      }
    }
  }
}
