.vacantest__checkboxes__row {
  align-items: center;
  display: flex;
  width: 100%;
}

.vacantest__checkboxes__item__name {
  width: 55%;
}

.vacantest__checkboxes {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 45%;
}

.vacantest__checkbox__individual__container,
.vacantest__individual__checkbox__title {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30%;
}

.vacantest__individual__checkbox__title h3 {
  color: #333;
  font-size: 1.3em;
}

.vacantest__checkbox {
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  height: 35px;
  display: flex;
  justify-content: center;
  outline: none;
  width: 35px;
  -webkit-appearance: none;
}

.vacantest__checkbox:after {
  color: white;
  content: "\f00c";
  display: none;
  font-family: "FontAwesome";
  font-size: 20px;
  font-weight: 900;
}

.vacantest__checkbox:checked {
  background-color: #333;
  border: none;
}

.vacantest__checkbox:checked:after {
  display: block;
}

.vacantest__checkbox:disabled {
  border: 1px solid #e8ecf0;
  background-color: #e8ecf0;
}
