.bold_text {
  font-weight: 600;
  margin: 5% 25px 5% 0 !important;
}

.radio_inputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.vacantest__checkbox {
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  height: 35px;
  display: flex;
  justify-content: center;
  outline: none;
  width: 35px;
  -webkit-appearance: none;
}

.vacantest__checkbox:after {
  color: white;
  content: "\f00c";
  display: none;
  font-family: "FontAwesome";
  font-size: 20px;
  font-weight: 900;
}

.vacantest__checkbox:checked {
  background-color: #333;
  border: none;
}

.vacantest__checkbox:checked:after {
  display: block;
}

.vacantest__checkbox:disabled {
  border: 1px solid #e8ecf0;
  background-color: #e8ecf0;
}

.limit {
  margin: 0;
  font-weight: 900;
}

.exploring__container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.check__label {
  margin: 2.5% 0 !important;
}

.last__dropdown {
  margin-bottom: 50px !important;
}