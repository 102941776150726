.value-selector {
  box-sizing: border-box;
  width: 600px;/* !important */
  /* max-height: 100vh; */
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__title {
    color: #000;
    font-weight: 600;
    font-size:  20px;
    font: var(--title-h-3-semibold, 600 22px "Inter", sans-serif);
  }

  &__subtitle {
    color: #000000;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
  }

  &__options {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:nth-child(1){
      margin-top: 48px;
    }
  }

  input {
    box-shadow: unset;
  }

  @media screen and (max-width: 600px) {
    width: 100%;

    &__options {
      width: 100%;
    }
  }
}

.flow__slider {
  color: rgb(0, 150, 237);
  height: 8px;
  & .MuiSlider-track {
    border: none;
  }
  & .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid currentColor;
    
    &:focus, &:hover, &.Mui-active, &.Mui-focusVisible {
      box-shadow: inherit;
    }
    &::before {
      display: none;
    }
  }
  & .MuiSlider-markLabel {
    font-weight: 600;
  }
  & .MuiSlider-valueLabel {
    /* line-height: 1.2px; */
    font-size: 12px;
    font-weight: 600;
    background: unset;
    /* padding: 0;
    width: 32px;
    height: 32px; */
    border-radius: 8px;
    color: #fff;
    background-color: rgb(0, 150, 237);
    /* transform-origin: bottom left;
    transform: translate(50%, -100%) rotate(-45deg) scale(0);
    &::before { 
      display: none;
    }
    &.MuiSlider-valueLabelOpen {
      transform: translate(50%, -100%) rotate(-45deg) scale(1);
    }
    & > * {
      transform: rotate(45deg);
    } */
  }

  &.invalid {
    & .MuiSlider-valueLabel {
      background-color: #eaeaea!important;
      color: #b3b3b3!important;
    }
  }
}

.budget-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .budget-title {

  }
  
  .budget__slider {
    margin-top: 44px;
    box-sizing: border-box!important;
    padding: 0!important;
    width: 90%!important;
    align-self: center!important;
  }
}