.bold_text {
  font-weight: 600!important;
}

.small_text {
  font-size: 1em!important;
}

.radio_inputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.last__dropdown {
  margin-bottom: 25px;
}
