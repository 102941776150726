.content {
  background-color: white;
  min-height: calc(100vh - 100px);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;

  @media (max-width: 575.98px) {
    font-size: 16px;
  }
}

.img_content {
  height: 250px;

  .img_file {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 10%;
  }
}

.text_content {
  background-color: white;
  width: 50%;
  margin: 0 auto;
  margin-top: 70px;
}

.main_header {
  text-align: start;
  color: black;

  h4{
    font-weight: 600;
  }
}

.term_header {
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
  display: block;
}

.terms_wrapper {
  margin-top: 50px;
}

.term {
  margin-bottom: 40px;

  a:link {
  color: blue;
}

  a:visited {
    color: gray;
  }

  a:hover {
    color: tomato;
  }

  a:active {
    color: greenyellow;
  }

  h5 {
    font-weight: 400;
    color: black;
  }

  p {
    text-align:justify;
  }

  ul {

    li {
      list-style-type: disc;
      list-style-position: inside;
      text-align: start;
      padding: 10px;
    }
  }
}

.term_list {
  margin-left: 40px;
  color: inherit;
  font-size: 16px;
}

.email {
  text-decoration: underline;
  color: blue;
}
