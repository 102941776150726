.flow-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 600px;

  & > * {
    width: 100%;
  }

  .flow-step__title {
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    margin: 0 auto;
  
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
  
  }
  
  .flow-page__title {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  
  .flow-page__subtitle {
    color: #666666;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    margin-top: 4px;
  }

  .divider {
    // height: 36px;
  }

  .questions {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .value-selector__options:nth-child(1) {
      margin-top: 0px!important;
    }
  }
}

@media screen and (max-width: 640px) {
  .flow-content {
    width: 100%;
  }
}
