.cta_btn {
  background-color: #0296ec;
  color: white;
  width: 75%;
  font-family: 'Inter', 'raleway', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  height: 50px;
}

.loader__container {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 75%;
}

.limit {
  font-weight: 800;
}

.sign_up_box {
  align-items: center;
  background-color: #dcdcdc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  justify-content: center;
  margin-top: 0;
  padding: 0 20px 15px 20px;
  width: 100%;
}

.button_box {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50%;
}

.button_box a {
  font-family: 'Inter', 'raleway', sans-serif;
}

.sign_up_text {
  width: 50%;
}

.sign_up_btn {
  background-color: #686566;
  color: white;
  width: 80%;
  border: none;
  border-radius: 3px;
  height: 50px;
  text-align: center;
}

.sign_up_anchor {
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  color: white;
}

.send_mail {
  border: 2px solid #686566;
  margin-top: 20px;
  padding: 0 15px 15px 15px;
  border-radius: 5px;
  font-size: 10px;
}

.send_mail_text {
  h4 {
    color: #0296ec;
    margin-top: 10px;
    margin-bottom: -20px;
  }
}

.input_mail {
  border: 2px solid #686566;
  box-shadow: none;
  height: 45px;
  font-size: 15px;
  margin-right: 10px;
  padding: 0 10px;
  text-align: start;
  width: 45%;
}

.invalid__input {
  background-color: #fffafa;
  border: 3px solid #dc3545;
  border-radius: 0.1875rem;
  box-shadow: none !important;
  color: #677897;
  font-weight: normal;
  padding: 10px;
  width: 100%;
}

.send_mail_btn {
  background-color: #908e8e;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border: none;
  width: 50%;
  height: 45px;
  float: right;
}


.signUpBox__title {
  width: 100%;
}

.signUpBox__title h4 {
  margin: 15px 0 !important;
}

@media all and (max-width: 1023px) {
  .cta_btn {
    width: 100%;
  }
}
