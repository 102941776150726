.miniCard__card {
  background-color: #fff;
  border-radius: 4px;

  max-width: fit-content;

  margin: -80px 0 20px 0;
  padding: 18px 27px 27px 18px;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.miniCard__card .miniCard__firstRow {
  display: flex;
  align-items: center;

  height: 135px;
}

.miniCard__card .miniCard__firstRow img {
  object-fit: cover;
  height: 100%;
}

.miniCard__card .miniCard__firstRow .miniCard__content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin-left: 18px;
  height: 100%;
}

.miniCard__card .miniCard__firstRow .miniCard__content p {
  margin: 0;
  padding: 0;

  font-weight: 500;
  color: #6c757d;
}
.miniCard__card .miniCard__firstRow .miniCard__content .locations {
  width: 100%;
}
.miniCard__card .miniCard__firstRow .miniCard__content .locations span {
  display: inline;
  margin-right: 10px;
}

.miniCard__card .miniCard__firstRow .miniCard__content .mainCard__heading {
  color: #333;
  font-family: 'Inter', 'raleway', sans-serif;
  font-weight: 800;
  line-height: normal;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  
  margin: 0;
  padding: 0;
}

.miniCard__card .miniCard__firstRow .miniCard__content .mainCard__subHeading {
  color: #6c757d;
  font-family: 'Inter', 'raleway', sans-serif;
  font-weight: 600;
  line-height: normal;
  font-size: .9rem;

  margin: 0;
  padding: 0;
}

.miniCard__card .miniCard__firstRow .miniCard__content .miniCard__ratings {
  display: flex;
  align-items: center;
}

.miniCard__card .miniCard__firstRow .miniCard__content .miniCard__ratings p {
  margin: 0 0 0 5px;
}

.miniCard__card .miniCard__secondRow {
  height: 20px;
}

.verifiedMsg__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.verifiedMsg, .notVerifiedMsg {
  margin: 0 10px 0 0;

  font-weight: 500;
  font-size: 12px;
}

.verifiedMsg {
  color: #333;
}

.notVerifiedMsg {
  color: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px) {
  .miniCard__card {
    padding: 12px 2px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .miniCard__card .miniCard__firstRow {
    display: flex;
    flex-direction: column;
    height: unset;
  }
  
  .miniCard__card .miniCard__firstRow > img {
    height: 200px;
    margin-bottom: 10px;
  }
  
  .miniCard__card .miniCard__firstRow .miniCard__content {
    margin: 0;
    padding: 10px;
  }
  .miniCard__card .miniCard__firstRow .miniCard__content .locations span {
    display: block;
    width: 100%;
  }
  .miniCard__card .miniCard__firstRow .miniCard__content > p {
    margin-bottom: 10px !important;
  }
  
  .miniCard__card .miniCard__firstRow .miniCard__content .mainCard__heading {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
}