.terms__and__conditions__content {
  background-color: white;
  min-height: calc(100vh - 100px);
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 30px;

  @media (max-width: 575.98px) {
    font-size: 16px;
  }
}

.img_content {
  height: 250px;
  height: 300px;
  position: relative;
  
  .img_file {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 10%;
  }
}

.term_ul {
  margin: 0%;
  color: inherit;
  font-size: 16px;
  margin-left: 30px;
}

.text_content {
  background-color: white;
  width: 50%;
  margin: 0 auto;
  margin-top: 70px;
}

.terms_header {
  text-align: start;
  color: black;

  h4{
    font-weight: 600;
  }
}

.terms_wrapper {
  margin-top: 50px;
}

.term {
  margin-bottom: 40px;

  a:link {
  color: blue;
}

  h5 {
    font-weight: 400;
    color: black;
  }

  p {
    text-align:justify;
  }

  ul {

    li {
      list-style-type: disc;
      list-style-position: inside;
      text-align: start;
      padding: 10px;
    }
  }
}

.term:last-child {
  margin-bottom: 0;
}
