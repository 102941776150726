@import 'index.sass'
$button-height: 50px
$color: #36a7eb

#root
  margin-top: 69px

button
  color: #1a1a1a

.base-button 
  @include button-base(#{$button-height}, $color)

h5.secondary-title
  color: var(--themeFont)
  font-weight: 600
  font-size: 1.2em

div.limit-row 
  max-width: 130px !important


a.card-title  
  inherit: none
  color: var(--themeFont)
  font-weight: 500
  font-size: 16px

div.theme-font,p.theme-font
  font-size: 1em

.theme-font-sm
  font-size: 1em

.font-weight-lightBold
  font-weight: 400

.sl 
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.shadow
  @include custom-shadow()

.strong-title 
  color: var(--themeFont)

.clickable
  cursor: pointer

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-Thin.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-Thin.woff") format("woff"), url("./assets_now/fonts/inter/Inter-Thin.ttf") format("truetype")
  font-weight: 100
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-ExtraLight.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-ExtraLight.woff") format("woff"), url("./assets_now/fonts/inter/Inter-ExtraLight.ttf") format("truetype")
  font-weight: 200
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-Light.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-Light.woff") format("woff"), url("./assets_now/fonts/inter/Inter-Light.ttf") format("truetype")
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-Regular.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-Regular.woff") format("woff"), url("./assets_now/fonts/inter/Inter-Regular.ttf") format("truetype")
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-Medium.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-Medium.woff") format("woff"), url("./assets_now/fonts/inter/Inter-Medium.ttf") format("truetype")
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-SemiBold.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-SemiBold.woff") format("woff"), url("./assets_now/fonts/inter/Inter-SemiBold.ttf") format("truetype")
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-Bold.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-Bold.woff") format("woff"), url("./assets_now/fonts/inter/Inter-Bold.ttf") format("truetype")
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-ExtraBold.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-ExtraBold.woff") format("woff"), url("./assets_now/fonts/inter/Inter-ExtraBold.ttf") format("truetype")
  font-weight: 800
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url("./assets_now/fonts/inter/Inter-Black.woff2") format("woff2"), url("./assets_now/fonts/inter/Inter-Black.woff") format("woff"), url("./assets_now/fonts/inter/Inter-Black.ttf") format("truetype")
  font-weight: 900
  font-style: normal

body
  font-family: Inter, sans-serif!important
