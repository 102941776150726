.vacantest__range__container {
  /* background-color: tomato; */
  margin: 50px 0;
  width: 80%;
}

.vacantest__range__title h5 {
  font-family: 'Inter', 'raleway', sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-align: left;
}

.vacantest__range {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: 20px auto 0;
  width: 100%;
}

.vacantest__range__input {
  box-shadow: none !important;
  height: 29px !important;
  -webkit-appearance: none !important;
  margin: 10px 0 !important;
  width: 100% !important;
}

.vacantest__range {
  position: relative;
}

.vacantest__range__underbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.vacantest__range__underbar .smaller__range {
  color: #333;
  font-family: var(--font__roboto);
  font-size: 1.3em;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 6%;
}

@media all and (max-width: 767px) {
  .vacantest__range__container {
    width: 90%;
  }
}